function SpaceButton() {
  return (
    <div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					x="0"
					y="0"
					width="140"
					viewBox="25 21 90 29"
				>
					<g>
						<g>
							<path fill="#fff" stroke="currentColor" strokeWidth="0.7" d="M 110.516 21.25 L 31.44 21.25 C 29.17 21.25 27.32 23.1 27.32 25.37 L 27.32 45.17 C 27.32 47.45 29.17 49.3 31.44 49.3 L 110.516 49.3 C 112.786 49.3 114.636 47.45 114.636 45.17 L 114.636 25.37 C 114.636 23.1 112.786 21.25 110.516 21.25 Z M 32.27 25.37 C 32.27 24.01 33.38 22.9 34.74 22.9 L 107.216 22.9 C 108.576 22.9 109.686 24.01 109.686 25.37 L 109.686 38.57 C 109.686 39.94 108.576 41.05 107.216 41.05 L 34.74 41.05 C 33.38 41.05 32.27 39.94 32.27 38.57 L 32.27 25.37 Z M 112.986 45.17 C 112.986 46.54 111.876 47.65 110.516 47.65 L 31.44 47.65 C 30.08 47.65 28.97 46.54 28.97 45.17 L 28.97 25.37 C 28.97 24.01 30.08 22.9 31.44 22.9 L 31.46 22.9 C 30.94 23.59 30.62 24.44 30.62 25.37 L 30.62 38.57 C 30.62 40.85 32.47 42.7 34.74 42.7 L 107.216 42.7 C 109.486 42.7 111.336 40.85 111.336 38.57 L 111.336 25.37 C 111.336 24.44 111.016 23.59 110.496 22.9 L 110.516 22.9 C 111.876 22.9 112.986 24.01 112.986 25.37 L 112.986 45.17 Z"/>
						</g>
					</g>
					<g stroke="none">
						<text x="70" y="35" fill="#fff" fontSize="12px" fontFamily="Fjalla One" >SPACE</text>
					</g>
				</svg>
    </div>
  );
}
export default SpaceButton;