function ArrowButtons() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="140"
        viewBox="5 20 90 60"
      >
        <g>
          <g>
            <path
              fill="#fff"
              stroke="currentColor"
              strokeWidth="0.7"
              d="M51.24,21.25h-19.8c-2.27,0-4.12,1.85-4.12,4.12v19.8c0,2.28,1.85,4.13,4.12,4.13h19.8c2.27,0,4.12-1.85,4.12-4.13v-19.8    C55.36,23.1,53.51,21.25,51.24,21.25z M32.27,25.37c0-1.36,1.11-2.47,2.47-2.47h13.2c1.36,0,2.47,1.11,2.47,2.47v13.2    c0,1.37-1.11,2.48-2.47,2.48h-13.2c-1.36,0-2.47-1.11-2.47-2.48V25.37z M53.71,45.17c0,1.37-1.11,2.48-2.47,2.48h-19.8    c-1.36,0-2.47-1.11-2.47-2.48v-19.8c0-1.36,1.11-2.47,2.47-2.47h0.02c-0.52,0.69-0.84,1.54-0.84,2.47v13.2    c0,2.28,1.85,4.13,4.12,4.13h13.2c2.27,0,4.12-1.85,4.12-4.13v-13.2c0-0.93-0.32-1.78-0.84-2.47h0.02c1.36,0,2.47,1.11,2.47,2.47    V45.17z"
            />
            <path
              fill="#fff"
              stroke="currentColor"
              strokeWidth="0.7"
              d="M59.9,50.7H40.1c-2.27,0-4.12,1.85-4.12,4.13v19.8c0,2.27,1.85,4.12,4.12,4.12h19.8c2.27,0,4.12-1.85,4.12-4.12v-19.8    C64.02,52.55,62.17,50.7,59.9,50.7z M40.93,54.83c0-1.37,1.11-2.48,2.47-2.48h13.2c1.36,0,2.47,1.11,2.47,2.48v13.2    c0,1.36-1.11,2.47-2.47,2.47H43.4c-1.36,0-2.47-1.11-2.47-2.47V54.83z M62.37,74.63c0,1.36-1.11,2.47-2.47,2.47H40.1    c-1.36,0-2.47-1.11-2.47-2.47v-19.8c0-1.37,1.11-2.48,2.47-2.48h0.02c-0.52,0.7-0.84,1.55-0.84,2.48v13.2    c0,2.27,1.85,4.12,4.12,4.12h13.2c2.27,0,4.12-1.85,4.12-4.12v-13.2c0-0.93-0.32-1.78-0.84-2.48h0.02c1.36,0,2.47,1.11,2.47,2.48    V74.63z"
            />
            <path
              fill="#fff"
              stroke="currentColor"
              strokeWidth="0.7"
              d="M89.73,50.7h-19.8c-2.27,0-4.12,1.85-4.12,4.13v19.8c0,2.27,1.85,4.12,4.12,4.12h19.8c2.27,0,4.13-1.85,4.13-4.12v-19.8    C93.86,52.55,92,50.7,89.73,50.7z M70.76,54.83c0-1.37,1.11-2.48,2.47-2.48h13.2c1.37,0,2.48,1.11,2.48,2.48v13.2    c0,1.36-1.11,2.47-2.48,2.47h-13.2c-1.36,0-2.47-1.11-2.47-2.47V54.83z M92.21,74.63c0,1.36-1.11,2.47-2.48,2.47h-19.8    c-1.36,0-2.47-1.11-2.47-2.47v-19.8c0-1.37,1.11-2.48,2.47-2.48h0.03c-0.53,0.7-0.85,1.55-0.85,2.48v13.2    c0,2.27,1.85,4.12,4.12,4.12h13.2c2.28,0,4.13-1.85,4.13-4.12v-13.2c0-0.93-0.33-1.78-0.85-2.48h0.02c1.37,0,2.48,1.11,2.48,2.48    V74.63z"
            />
            <path
              fill="#fff"
              stroke="currentColor"
              strokeWidth="0.7"
              d="M30.07,50.7h-19.8c-2.27,0-4.13,1.85-4.13,4.13v19.8c0,2.27,1.86,4.12,4.13,4.12h19.8c2.27,0,4.12-1.85,4.12-4.12v-19.8    C34.19,52.55,32.34,50.7,30.07,50.7z M11.09,54.83c0-1.37,1.11-2.48,2.48-2.48h13.2c1.36,0,2.47,1.11,2.47,2.48v13.2    c0,1.36-1.11,2.47-2.47,2.47h-13.2c-1.37,0-2.48-1.11-2.48-2.47V54.83z M32.54,74.63c0,1.36-1.11,2.47-2.47,2.47h-19.8    c-1.37,0-2.48-1.11-2.48-2.47v-19.8c0-1.37,1.11-2.48,2.48-2.48h0.02c-0.52,0.7-0.85,1.55-0.85,2.48v13.2    c0,2.27,1.85,4.12,4.13,4.12h13.2c2.27,0,4.12-1.85,4.12-4.12v-13.2c0-0.93-0.32-1.78-0.85-2.48h0.03c1.36,0,2.47,1.11,2.47,2.48    V74.63z"
            />
          </g>
        </g>
        <path
          fill="#fff"
          d="M 19.124 59.763 H 19.124 L 19.124 59.263 L 27.124 63.263 L 19.124 67.263 L 19.124 66.763 H 19.124 V 59.763 Z"
          style={{
            transformBox: "fill-box",
            transformOrigin: "50% 50%",
          }}
          transform="matrix(0, 1, -1, 0, 29.168671, -4.85994)"
        />
        <path
          fill="#fff"
          d="M 78.377 54.806 H 78.377 L 78.377 54.306 L 86.377 58.306 L 78.377 62.306 L 78.377 61.806 H 78.377 V 54.806 Z"
          style={{
            transformOrigin: "86.377px 58.306px",
          }}
        />
        <path
          fill="#fff"
          d="M 19.124 59.763 H 19.124 L 19.124 59.263 L 27.124 63.263 L 19.124 67.263 L 19.124 66.763 H 19.124 V 59.763 Z"
          transform="matrix(0, -1, 1, 0, -19.061556, 52.047195)"
        />
        <path
          fill="#fff"
          d="M -26.967 -61.994 H -26.967 L -26.967 -62.494 L -18.967 -58.494 L -26.967 -54.494 L -26.967 -54.994 H -26.967 V -61.994 Z"
          style={{
            transformOrigin: "-18.967px -58.494px",
          }}
          transform="matrix(-1, 0, 0, -1, 37.93486, 116.987999)"
        />
      </svg>
    </div>
  );
}
export default ArrowButtons;
